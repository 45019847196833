import React from "react";

export default function Navigation() {
  return (
    <div style={{ textAlign: "center" }}>
      <a href="/service">이용약관</a>
      <p style={{ display: "inline-block", marginLeft: 10, marginRight: 10 }}>
        |
      </p>
      <a href="/policy">개인정보처리방침</a>
      <p style={{ display: "inline-block", marginLeft: 10, marginRight: 10 }}>
        |
      </p>
      <a href="/business">업무위수탁약관</a>
      <p style={{ display: "inline-block", marginLeft: 10, marginRight: 10 }}>
        |
      </p>
      <a href="/location">위치기반서비스 이용약관</a>
    </div>
  );
}
