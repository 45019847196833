import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./assets/css/main.css";
import Policy from "./component/Policy";
import Service from "./component/Service";
import Business from "./component/Business";
import Location from "./component/Location";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/service" />} />
        <Route path="/location" element={<Location />} />
        <Route path="/business" element={<Business />} />
        <Route path="/service" element={<Service />} />
        <Route path="/policy" element={<Policy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
