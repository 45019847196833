import React from "react";
import logo from "./../assets/img/logo.svg";

export default function Header() {
  return (
    <div
      id="header"
      style={{
        backgroundColor: "transparent",
        borderBottom: "none",
        boxShadow: "none",
      }}
    >
      <div className="header-in">
        <div className="logo">
          <img src={logo} height="36px" alt="로고" />
        </div>
      </div>
    </div>
  );
}
